import Thunderstorm from '../assets/images/thunderstorm.svg'
import ShowerRain from '../assets/images/shower-rain.svg'
import Rain from '../assets/images/rain.svg'
import ClearSky from '../assets/images/clear-sky.svg'
import FewClouds from '../assets/images/few-clouds.svg'
import ScatteredClouds from '../assets/images/scattered-clouds.svg'
import BrokenClouds from '../assets/images/broken-clouds.svg'


const getWeatherIcon = (id) => {
  // Thunderstorm
  if (id >= 200 && id < 300) {
    return Thunderstorm
  }
  // Dizzle
  if (id >= 300 && id < 400) {
    return Rain
  }
  // Rain
  if (id >= 500 && id < 600) {
    return Rain
  }
  // Snow
  if (id >= 600 && id < 700) {
    return ShowerRain
  }
  // Atmosphere
  if (id >= 700 && id < 800) {
    return FewClouds
  }
  // Clear
  if (id === 800) {
    return ClearSky
  }
  if (id === 801) {
    return FewClouds
  }
  if (id === 802) {
    return ScatteredClouds
  }
  if (id === 803 || id === 804) {
    return BrokenClouds
  }
}

export default getWeatherIcon
