import React, { Component } from 'react'
import Link from '../utils/link'

import Srl from '../assets/images/srl.svg'
import Facebook from '../assets/images/facebook.svg'
import Instagram from '../assets/images/instagram.svg'

class Footer extends Component {
  render() {
    let { menu, hours } = this.props.wp.siteOptions.settings
    return (
      <footer className='footer'>
        <div className='footer__grid'>
          <div className='footer__inner'>
            <div className='footer__top'>
              <div className='footer__col'>
                <p>Botanic Ridge Village is proudly brought to you by the team at Sandhurst Retail &amp; Logistics.</p>
                <p>We respectfully acknowledge the Traditional Owners of the land on which we work, the Boon Wurrung and Woiwurrung (Wurundjeri) peoples of the Kulin Nation and we pay respect to their Elders, past and present.</p>
                <p>© Copyright Sandhurst Retail &amp; Logistics { new Date().getFullYear() }</p>
              </div>
              <div className='footer__col'>
                { menu?.length > 0 &&
                  <ul>
                    { menu.map((el, i) => (
                      <li key={i}><Link to={el.link} key={i}>{el.text}</Link></li>
                    )) }
                  </ul>
                }
              </div>
              { hours &&
                <div className='footer__col'>
                  { hours.map((el, i) => (
                    <p className='hours' key={i}>
                      <span>{el.days}</span><span>{el.hours}</span>
                    </p>
                  )) }
                </div>
              }
              <div className='footer__col'>
                <p>10 Hummingbird Drive, Botanic Ridge, 3977, Victoria</p>
                <p><Link to='mailto:info@botanicridgevillage.com.au'>info@botanicridgevillage.com.au</Link></p>
                <p><Link className='facebook' to='https://facebook.com/botanicridgevillagesc/'><img src={Facebook} alt='Facebook' />Facebook</Link><br /><Link className='instagram' to='https://instagram.com/botanicridgevillage/'><img src={Instagram} alt='Instagram' /> Instagram</Link></p>
              </div>
            </div>
            <div className='footer__bottom'>
              <div className='footer__col'>
                <Link to='https://srl.com.au/' title='Sandhurst Retail & Logistics' className='footer__logo'>
                  <img src={Srl} alt='Sandhurst Retail & Logisitics 2021' />
                </Link>
              </div>
              <div className='footer__col'>&nbsp;</div>
              <div className='footer__col'>
                <ul>
                  <li><Link to='/privacy-policy/'>Privacy Policy</Link><br /><Link to='/terms-of-use/'>Terms of Use</Link></li>
                </ul>
              </div>
              <div className='footer__col'>
                <ul>
                  <li><Link to='https://y-m-d.com'>Site by YMD</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
