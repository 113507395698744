import React, { Component } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import Fade from 'react-reveal/Fade'
import Link from '../utils/link'
import { result } from 'lodash'

class Newsletter extends Component {
  state = {
    firstname: '',
    lastname: '',
    email: '',
    msg: 'Subscribe'
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  // 2. via `async/await`
  handleSubmit = async (e) => {
    e.preventDefault();
    let data = {}
    const result = await addToMailchimp(this.state.email, {
      FNAME: this.state.firstname,
      LNAME: this.state.lastname,
    })
    // I recommend setting `result` to React state
    // but you can do whatever you want
    if (result.result === 'success') this.setState({ msg: "Thank you for subscribing" })
    if (result.result === 'error') this.setState({ msg: "Thank you for subscribing" })
  }

  render() {

    let newsletterRef = (ref) => this.newsletterRef = ref
    
    let props = {
      ref: newsletterRef,
      name: "newsletter",
      className: "form form--newsletter",
      onSubmit: this.handleSubmit,
      "data-netlify": "true",
      "data-netlify-honeypot": "bot-field",
    }

    let { msg } = this.state

    return (
      <section className='newsletter'>
        <div className='newsletter__grid'>
          <div className='newsletter__title'>
            <div className='newsletter__inner'>
              <Fade><h4>Stay Updated</h4></Fade>
            </div>
          </div>
          <div className='newsletter__inner'>
            <Fade>
              <p>With news, offers, store openings and more:</p>
              <form {...props}>
                <div className="form__row form__row--double">
                  <input
                    type="text"
                    name="firstname"
                    placeholder="name..."
                    onChange={this.handleChange}
                    required
                  />
                  <input
                    type="text"
                    name="lastname"
                    placeholder="surname..."
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form__row">
                  <input
                    type="email"
                    name="email"
                    placeholder="email..."
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className='form__row form__row--submit'>
                  <button type="submit" className="btn">
                    <span className='circle'>
                      { msg !== 'Subscribe' && 
                        <span className='arrow'>
                          <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M19.1804 1.92544L8.03768 16.1317L1.1252 8.7893L3.3095 6.73289L7.83041 11.535L16.8199 0.0739632L19.1804 1.92544Z" fill="#3351A3"/>
                          </svg>
                        </span>
                      }
                      { msg === 'Subscribe' && 
                        <span className='arrow'>→</span>
                      }
                    </span>
                    <span className='msg'>{msg}</span>
                  </button>
                </div>
              </form>
              <p className='privacy'>*All information will be managed in accordance with our <Link to='/privacy-policy/'>Privacy Policy.</Link></p>
            </Fade>
          </div>
        </div>
      </section>
    )
  }
}

export default Newsletter