import React, { Component } from 'react'
import Link from '../utils/link'

import Logo from '../assets/images/logo.svg'
import getWeatherIcon from '../utils/weather-icons'
const moment = require('moment-timezone')

class Header extends Component {

  constructor(props) {
    super(props)
    this.state = {
      offCanvas: false,
      open: true,
      weather: null,
      hover: false,
      clicked: false,
      day: moment().tz("Australia/Melbourne").isoWeekday() ? moment().tz("Australia/Melbourne").isoWeekday() - 1 : 0
    }
  }

  componentDidMount() {
    let { open, day } = this.state
    let { openingHours } = this.props.wp.siteOptions.settings
    fetch('https://api.openweathermap.org/data/2.5/weather?q=Casey,VIC,AU&units=metric&appid=4473b1fa17305953a04a6b52d98f20d8')
      .then(response => response.json())
      .then(data => {
        this.setState({ weather: data })
      })
    let melbourneTime = moment().tz("Australia/Melbourne"),
      format = 'hh:mm:ss',
      time = moment(melbourneTime, format),
      beforeTime = moment(openingHours[day].open.time, format),
      afterTime = moment(openingHours[day].close.time, format)

    if (time.isBetween(beforeTime, afterTime)) {
      open = true
    } else {
      open = false
    }
    let sortedOpeningHours = openingHours
    let sunday = sortedOpeningHours.shift()
    sortedOpeningHours.push(sunday)
    this.setState({
      day: day,
      open: open,
      sortedOpeningHours: sortedOpeningHours
    })
  }

  _getWeatherIcon(id) {

  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  _showHours = () => {
    this.setState({ hover: true })
  }

  _hideHours = () => {
    this.setState({ hover: false })
  }

  _toggleHours = () => {
    this.setState({ clicked: !this.state.clicked })
  }

  render() {

    let { offCanvas, open, weather, hover, clicked, day, sortedOpeningHours } = this.state

    let { menu, openingHours } = this.props.wp.siteOptions.settings 

    let props = {
      onClick: this._hideOffCanvas,
      activeclassname: 'active'
    }

    return (
      <>
        <header className='header'>
          <div className='header__top'>
            <div className='header__grid'>
              <div className='header__inner'>
                <div className='header__info'>
                  <div className='header__weather'>
                    { weather?.weather?.length > 0 &&
                      <div>
                        <img src={getWeatherIcon(weather.weather[0].id)} alt='Sunny' />
                        <span>{ weather.weather[0].description }&nbsp; { Math.round(weather.main.temp) }° C</span>
                      </div>
                    }
                  </div>
                  <div className={`header__hours ${hover ? 'hover' : ''} ${clicked ? 'clicked' : ''}`} onClick={this._toggleHours} onMouseOver={this._showHours} onMouseLeave={this._hideHours}>
                    <div className='header__hours-rollover'>
                      { openingHours[day].closed && <span className='label'>Closed</span> }
                      { !openingHours[day].closed && 
                        <>
                          <span className='label'>{ open ? 'Open Now' : 'Closed' }</span>
                          <span className='current-time'>{openingHours[day].open.label} — {openingHours[day].close.label}</span>
                        </>
                      }
                    </div>
                    { sortedOpeningHours &&
                      <ul>
                        { sortedOpeningHours.map((el, i) => (
                          <li key={i}>
                            <span className='day'>{el.days}</span>
                            { el.closed && <span className='times'>Closed</span> }
                            { !el.closed && <span className='times'>{el.open.label} — {el.close.label}</span> }
                          </li>
                        )) }
                      </ul>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='header__bottom'>
            <div className='header__grid'>
              <div className='header__inner'>
                <Link to='/' title='Site Name' className='header__logo' {...props}>
                  <img src={Logo} alt='Botanic Ridge Village' />
                </Link>
                <nav className='header__nav'>
                  { menu?.length > 0 && 
                    <ul>
                      { menu.map((el, i) => (
                        <li key={i}><Link to={el.link} key={i} {...props}>{el.text}</Link></li>
                      )) }
                    </ul>
                  }
                </nav>
                <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
                  <span className='lines'></span>
                </button>
              </div>
            </div>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__grid'>
            <div className='off-canvas__inner'>
              <nav className='off-canvas__nav'>
                <ul>
                  <li><Link to='/about/' {...props}>About</Link></li>
                  <li><Link to='/stores/' {...props}>Stores</Link></li>
                  <li><Link to='/noticeboard/' {...props}>Noticeboard</Link></li>
                  <li><Link to='/contact/' {...props}>Contact</Link></li>
                  <li><Link to='https://facebook.com/botanicridgevillagesc/' {...props}>Facebook</Link><br /><Link to='https://instagram.com/botanicridgevillage/' {...props}>Instagram</Link></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Header
